import React from "react";
import { NavLink, NavLinks } from "components/headers/light";

export default () => {
  return (
    <NavLinks key={1}>
      <NavLink href="/">Inicio</NavLink>
      <NavLink href="/quienes-somos">¿Quiénes somos?</NavLink>
      <NavLink href="/papeleria">Papelería</NavLink>
      <NavLink href="/tramites-digitales">Trámites Digitales</NavLink>
      <NavLink href="/servicios">Servicios</NavLink>
      <NavLink href="/calculadora">Calculadora de Comisiones</NavLink>
      <NavLink href="/contacto">Contacto</NavLink>
    </NavLinks>
  );
};

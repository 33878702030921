import React, { useEffect, useState, useRef } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Header from "components/headers/light.js";
import { useDebouncedCallback } from "use-debounce";
import NavLinks from "components/misc/PapeNavLinks";
import papelerialeo5 from "images/papeleria-leo-5.jpg";

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Input = tw.input`w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 bg-gray-100 rounded-md px-6 py-3 text-sm font-medium  border-2 border-primary-100 focus:border-primary-500 hover:border-primary-500`;
const ClearButton = tw.button`bg-primary-200 rounded-md text-white px-4 py-2 text-sm font-medium  border-2 border-primary-200 hover:bg-primary-200 hover:border-primary-100 focus:border-primary-100`;

export default () => {
  const imageCss = tw`rounded-4xl`;

  const apiUrl = process.env.REACT_APP_BASE_API_SERVER_URL;
  const imgBaseUrl = process.env.REACT_APP_IMG_BASE_URL;

  // { imageSrc ,title ,content ,price ,rating ,reviews ,url}
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errorFetching, setErrorFetching] = useState(null);
  const filterRef = useRef(null);
  const [filter, setFilter] = useState("");

  const fetchProducts = async (restart = false) => {
    if (!page) {
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${apiUrl}/items/catalogue?page=${restart ? 1 : page}&limit=10${
          filter ? `&search=${filter}` : ""
        }`
      );
      const data = await response.json();
      setProducts([
        ...products,
        ...data.docs.map((item) => ({
          title: item.name,
          content: `${item.description.substring(0, 100)}...`,
          imageSrc: item.image
            ? `${imgBaseUrl}/stores/${item.store}/items/${item._id}_200.webp`
            : "",
        })),
      ]);
      setPage(data.nextPage);
    } catch (error) {
      setProducts([...products]);
      setPage(page);
      setErrorFetching(error);
      console.error(error);
    }
    setLoading(false);
  };

  const debouncedFetch = useDebouncedCallback(fetchProducts, 500);

  useEffect(() => {
    const search = filter?.toLowerCase().trim();
    if (search.length === 0) {
      setPage(1);
      setProducts([]);
      setLoading(true);
      debouncedFetch();
      return;
    }
    if (search.length < 3) {
      return;
    }
    setProducts([]);
    setPage(1);
    setLoading(true);
    debouncedFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <AnimationRevealPage>
      <Header links={[<NavLinks key={1} />]} />
      <Hero
        heading={
          <>
            Nuestra gama en &nbsp;
            <HighlightedText>Papelería</HighlightedText>
          </>
        }
        description="En Papelería e Internet Leo, estamos comprometidos a proporcionarte una amplia selección de suministros de oficina de alta calidad para todas tus necesidades. Nuestra gama de productos de papelería es la solución. Desde bolígrafos y papel hasta carpetas y organizadores, ofrecemos todo lo que necesitas."
        imageSrc={papelerialeo5}
        imageCss={imageCss}
        imageDecoratorBlob={true}
      />

      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Catálogo de <HighlightedText>productos.</HighlightedText>
          </>
        }
        subheading={
          <div
            style={{
              marginTop: 30,
            }}
          >
            <div style={{ fontWeight: "bold" }}>Buscar: </div>
            <Input
              ref={filterRef}
              value={filter}
              placeholder=""
              onChange={(e) => setFilter(e.target.value)}
            />
            &nbsp;
            {filter.length ? (
              <ClearButton onClick={() => setFilter("")}>Limpiar</ClearButton>
            ) : null}
            {filter.length && filter.length < 3 ? (
              <div style={{ color: "red", fontSize: 14 }}>
                Mínimo 3 caracteres
              </div>
            ) : null}
          </div>
        }
        showRating={false}
        // showOverlay={false}
        showPrice={false}
        tabs={[
          {
            name: "Papelería",
            products,
            infinite: {
              loading,
              fetchMoreData: debouncedFetch,
              errorFetching,
              length: products.length,
            },
          },
        ]}
        showTabControls={false}
      />
      {/* <Features
        heading={
          <>
            Amazing <HighlightedText>Services.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "230+ Locations",
            description: "Lorem ipsum donor amet siti ceali placeholder text",
            url: "https://google.com"
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Professional Chefs",
            description: "Lorem ipsum donor amet siti ceali placeholder text",
            url: "https://timerse.com"
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Birthday Catering",
            description: "Lorem ipsum donor amet siti ceali placeholder text",
            url: "https://reddit.com"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={<>Why <HighlightedText>Choose Us ?</HighlightedText></>}
        statistics={[
          {
            key: "Orders",
            value: "94000+",
          },
          {
            key: "Customers",
            value: "11000+"
          },
          {
            key: "Chefs",
            value: "1500+"
          }
        ]}
        primaryButtonText="Order Now"
        primaryButtonUrl="https://order.now.com"
        imageInsideDiv={false}
        imageSrc="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      />
      <DownloadApp
        text={<>People around you are ordering delicious meals using the <HighlightedTextInverse>Treact App.</HighlightedTextInverse></>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};

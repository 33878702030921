import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import NavLinks from "components/misc/PapeNavLinks";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import customerSatisfactionIcon from "images/customer-satisfaction-icon.png";
import qualityIcon from "images/quality-icon.png";
import digitalInnovationIcon from "images/digital-innovation-icon.png";
import papelerialeo6 from "images/papeleria-leo-6.jpg";
import papelerialeo4 from "images/papeleria-leo-4.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header links={[<NavLinks key={1} />]} />
      <MainFeature1
        subheading={<Subheading>Acerca de Papelería e Internet Leo</Subheading>}
        heading="Somos tu destino en papelería y trámites digitales."
        description="En Papelería e Internet Leo, hemos servido a la comunidad desde 2009 con pasión y dedicación. Somos una tienda que se enorgullece de ofrecer una amplia gama de productos de papelería, servicio de copiado, impresión y escáner. También te brindamos la asesoría que necesitas para realizar tus trámites digitales."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc={papelerialeo6}
      />
      <MainFeature1
        subheading={<Subheading>Nuestra Misión</Subheading>}
        heading="Solucionando tus necesidades diarias"
        description="Nuestro compromiso es ofrecer un servicio excepcional y soluciones innovadoras que satisfacen las necesidades de estudiantes, profesionales y creativos por igual. Creemos en el poder de las herramientas adecuadas para transformar ideas en realidades, y trabajamos con pasión para ser el recurso confiable y accesible de nuestra comunidad."
        buttonRounded={false}
        primaryButtonText="Contacto"
        primaryButtonUrl="contacto"
        imageSrc={papelerialeo4}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Nuestros Valores</Subheading>}
        heading="La esencia de Papelería e Internet Leo."
        description="Nuestros valores son la base de nuestro compromiso constante con la excelencia y en la forma en que interactuamos con nuestros clientes."
        imageCss={tw`w-10! h-10!`}
        cards={[
          {
            imageSrc: customerSatisfactionIcon,
            title: "Satisfacción del cliente",
            description:
              "Tu satisfacción es nuestro objetivo principal, y nos esforzamos por superar tus expectativas en cada paso.",
          },
          {
            imageSrc: qualityIcon,
            title: "Calidad",
            description:
              "Nos esforzamos por ofrecer productos y servicios de la más alta calidad para satisfacer las necesidades de nuestros clientes.",
          },
          {
            imageSrc: digitalInnovationIcon,
            title: "Innovación Digital",
            description:
              "Reconocemos la importancia de los trámites digitales y estamos aquí para ayudarte a navegar en un mundo cada vez más digitalizado.",
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid
        heading="Conoce a los miembros."
        subheading={<Subheading>Nuestro equipo</Subheading>}
        description="Nuestro equipo está formado por personas apasionadas y dedicadas que se esfuerzan por brindarte la mejor experiencia."
        cards={[
          {
            imageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
            name: "Ale",
            links: [],
          },
          {
            imageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
            name: "Martha",
            links: [],
          },
          {
            imageSrc:
              "https://images.unsplash.com/photo-1517070208541-6ddc4d3efbcb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
            name: "Leo",
            links: [],
          },
        ]}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};

import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Hero from "components/hero/EmptyWithCenteredContent";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ThreeColWithSideImage from "components/features/ThreeColWithSideImage.js";
import NavLinks from "components/misc/PapeNavLinks";
import bindingMachine from "images/binding-machine.jpg";
import copyMachine from "images/copy-machine.jpg";
import laminator from "images/laminator.jpg";
import cupIcon from "images/cup-icon.png";
import webIcon from "images/web-icon.png";
import vinylIcon from "images/vinyl-icon.png";

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

export default () => (
  <AnimationRevealPage>
    <Header links={[<NavLinks key={1} />]} />
    <Hero
      links={[]}
      heading={
        <>
          Nuestros <HighlightedText>Servicios</HighlightedText>
        </>
      }
      description={
        <>
          <br />
          <br />
          Desde copias y escaneo hasta acceso a internet, engargolado y
          enmicado, ofrecemos todo lo que necesitas para potenciar tu
          productividad
          <br />
          ¡Lo tenemos todo cubierto!
        </>
      }
    />
    <Features
      headingTitle="Más Solicitados"
      headingDescription="Estos son algunos de los servicios más solisitados por nuestros clientes."
      cards={[
        {
          imageSrc: copyMachine,
          title: "Copias y Escaneo",
          description: (
            <>
              Ofrecemos servicios de copiado y escaneo de alta calidad para
              garantizar que tus documentos estén disponibles en el formato que
              necesites.
              <br />
              - Copias blanco y negro hasta doble carta.
              <br />
              - Copias a color.
              <br />- Escaneado en pdf ó imagen.
            </>
          ),
        },
        {
          imageSrc: bindingMachine,
          title: "Engargolado",
          description: (
            <>
              Dale a tus documentos un toque profesional con nuestros servicios
              de engargolado, ideal para presentaciones y proyectos importantes.
            </>
          ),
        },
        {
          imageSrc: laminator,
          title: "Enmicado",
          description: (
            <>
              Protege tus documentos importantes mediante nuestros servicios de
              enmicado hasta tamaño oficio de calidad.
            </>
          ),
        },
      ]}
    />
    <ThreeColWithSideImage
      subheading="¿Necesitas algo más?"
      heading={
        <>
          Otros <HighlightedText>Servicios</HighlightedText>
        </>
      }
      description="Además de los servicios más solicitados, ofrecemos una amplia gama de servicios para satisfacer todas tus necesidades."
      cards={[
        {
          imageSrc: cupIcon,
          title: "Sublimado",
          description: "Sublimado de tazas, playeras",
        },
        {
          imageSrc: vinylIcon,
          title: "Vinil textil",
          description: "Vinil textil para playeras",
        },
        {
          imageSrc: webIcon,
          title: "Sitios web",
          description: "Desarrollo y mantenimiento de sitios web",
        },
      ]}
    />
    <Footer />
  </AnimationRevealPage>
);

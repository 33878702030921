import React, { useEffect } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Header from "components/headers/light.js";
import NavLinks from "components/misc/PapeNavLinks";

const Row = tw.div`flex flex-row items-end justify-center`;
const Cell = tw.div`flex flex-col px-2 items-center justify-end sm:px-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded border-2 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-24 text-center`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const ResultTitle = tw.div`text-sm lg:text-xl font-bold text-center text-primary-500 sm:px-2 py-2 md:px-6`;
const Result = tw.div`text-center sm:px-0 md:px-6 py-2 text-sm lg:text-base  `;

const Paragraph = tw.p`my-2 lg:my-2 text-sm lg:text-base font-medium text-gray-600 mx-auto lg:mx-0`;

export default () => {
  const [IVA, setIVA] = React.useState(16);
  const [amount, setAmount] = React.useState(0);
  const [service, setService] = React.useState({
    fee: 3.6,
    modes: [
      {
        key: "1payment",
        name: "1 pago",
        months: 1,
        fee: 0,
        total: 0,
        monthly: 0,
      },
      {
        key: "3months",
        name: "3 meses",
        months: 3,
        fee: 4.5,
        total: 0,
        monthly: 0,
      },
      {
        key: "6months",
        name: "6 meses",
        months: 6,
        fee: 7.5,
        total: 0,
        monthly: 0,
      },
      {
        key: "9months",
        name: "9 meses",
        months: 9,
        fee: 9.9,
        total: 0,
        monthly: 0,
      },
      {
        key: "12months",
        name: "12 meses",
        months: 12,
        fee: 11.95,
        total: 0,
        monthly: 0,
      },
    ],
  });
  const updateModeFee = (mode, value) => {
    mode.fee = value;
    setService({ ...service });
  };

  const calculate = () => {
    let divisor;
    let multiplier;
    service.modes.forEach(function (item, index) {
      if (amount < 500 && index > 0) {
        item.total = "N/A";
        item.monthly = "N/A";
        return;
      }
      divisor =
        1 -
        service.fee / 100 -
        ((service.fee / 100) * IVA) / 100 -
        item.fee / 100 -
        ((item.fee / 100) * IVA) / 100;
      multiplier = 1 / divisor;
      const product = amount * multiplier;
      const division = amount / divisor;
      if (product >= division) {
        item.total = (Math.round(product * 100) / 100).toFixed(2);
      } else {
        item.total = (Math.round(division * 100) / 100).toFixed(2);
      }
      if (!index) {
        item.monthly = 0;
      } else {
        item.monthly = parseInt(
          (Math.round((item.total / item.months) * 100) / 100).toFixed(2)
        );
      }
    });
    setService({ ...service });
  };

  useEffect(() => {
    calculate();
    // eslint-disable-next-line
  }, [amount, IVA, service.fee, service.modes]);

  return (
    <AnimationRevealPage>
      <Header links={[<NavLinks key={1} />]} />
      <Hero
        heading={
          <>
            <HighlightedText>Calculadora</HighlightedText> de precios para
            ventas con tarjeta
            <div style={{ fontSize: 20 }}>
              Entiende los costos asociados con las comisiones bancarias
            </div>
          </>
        }
        description="Esta calculadora está diseñada para ayudar a los dueños de negocios a entender cuánto deberían haber cobrado para cubrir el costo de la comisión que cobra el banco por el uso de tarjetas de crédito o débito en una transacción. Ingresando el precio original del producto o servicio y el porcentaje de comisión que cobra el banco, la calculadora te mostrará el precio ajustado que se habría requerido para que el negocio recibiera el monto íntegro de la venta."
        imageDecoratorBlob={true}
      />
      <div
        style={{
          fontSize: 28,
          textAlign: "center",
          marginBottom: 20,
          fontWeight: "bold",
        }}
      >
        Calculadora
      </div>
      <Row>
        <Cell>
          <div>IVA %</div>
          <div>
            <Input value={IVA} onChange={(e) => setIVA(e.target.value)} />
          </div>
        </Cell>
      </Row>
      <br />
      <br />
      <Row style={{ fontSize: 20, marginBottom: 8, alignItems: "center" }}>
        Comisiones %
        <div style={{ fontSize: 14, marginLeft: 8 }}>
          (Ingresa las comisiones de tu banco)
        </div>
      </Row>
      <Row style={{ flexWrap: "wrap" }}>
        <Cell>
          <div>Transacción</div>
          <div>
            <Input
              value={service.fee}
              onChange={(e) => setService({ ...service, fee: e.target.value })}
            />
          </div>
        </Cell>
        {service.modes.map((mode) => (
          <Cell key={mode.key}>
            <div>{mode.name}</div>
            <div>
              <Input
                value={mode.fee}
                onChange={(e) => {
                  updateModeFee(mode, e.target.value);
                }}
              />
            </div>
          </Cell>
        ))}
      </Row>
      <br />
      <br />
      <Row>
        <Cell>
          <label htmlFor="amount" style={{ marginBottom: 8 }}>
            Monto de la venta:
          </label>
          <div>
            <Input
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Total"
            />
          </div>
        </Cell>
      </Row>
      <br />
      <br />
      <Row>
        <Cell>
          <ResultTitle>Modalidad</ResultTitle>
          {service.modes.map((mode) => (
            <Result key={mode.key}>{mode.name}</Result>
          ))}
        </Cell>
        <Cell>
          <ResultTitle>Total</ResultTitle>
          {service.modes.map((mode) => (
            <Result key={mode.key}>{mode.total}</Result>
          ))}
        </Cell>
        <Cell>
          <ResultTitle>Mensualidad (aprox)</ResultTitle>
          {service.modes.map((mode) => (
            <Result key={mode.key}>{mode.monthly}</Result>
          ))}
        </Cell>
      </Row>
      <br />
      <br />
      <br />
      <br />
      <Cell>
        <div style={{ fontWeight: "bold", alignSelf: "start" }}>
          Importante:
        </div>
        <Paragraph>
          Es importante destacar que en México, la práctica de trasladar las
          comisiones bancarias al cliente no está permitida por la ley. Esta
          calculadora se ofrece solo con fines educativos e ilustrativos para
          que los dueños de negocios puedan entender mejor los costos asociados
          con el uso de tarjetas de crédito o débito. No debe ser utilizada como
          una herramienta para aplicar estas comisiones a los clientes.
        </Paragraph>
      </Cell>
      <br />
      <Cell>
        <div style={{ fontWeight: "bold", alignSelf: "start" }}>
          Advertencia Legal:
        </div>
        <Paragraph>
          El uso de esta calculadora no implica ni promueve la práctica de
          trasladar comisiones a los clientes. Al usar esta herramienta, aceptas
          que lo haces bajo tu propia responsabilidad y para propósitos
          educativos solamente. Siempre es recomendable seguir las normativas
          legales vigentes y consultar con un asesor legal si tienes dudas.
        </Paragraph>
      </Cell>
      <br />
      <br />
      <br />
      <Footer />
    </AnimationRevealPage>
  );
};

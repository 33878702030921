import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import InfiniteScroll from "components/InfiniteScroll";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(
  motion.div
)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12 min-h-40`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/4 md:w-1/5 lg:w-1/6 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(
  motion.div
)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-32 xl:h-40 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.8);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const SpinnerContainer = tw.div`flex justify-center items-center w-full py-10`;
const Spinner = tw.div`animate-spin inline-block w-20 h-20 border-8 border-t-primary-500 border-primary-100 rounded-full`;
// const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Checkout the Menu",
  tabs = [
    { name: "one", products: getRandomCards() },
    { name: "two", products: getRandomCards() },
    { name: "three", products: getRandomCards() },
  ],
  showRating = true,
  showOverlay = true,
  showPrice = true,
  showTabControls = true,
  subheading = null,
}) => {
  const tabsKeys = tabs.map((tab) => tab.name);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          {showTabControls && (
            <TabsControl>
              {tabs.map((tab, index) => (
                <TabControl
                  key={index}
                  active={activeTab === tab.name}
                  onClick={() => setActiveTab(tab.name)}
                >
                  {tab.name}
                </TabControl>
              ))}
            </TabsControl>
          )}
        </HeaderRow>
        {subheading}
        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              },
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs
              .find(({ name }) => name === tabKey)
              .products.map((card, index) => (
                <CardContainer key={index}>
                  <Card
                    className="group"
                    initial="rest"
                    whileHover="hover"
                    animate="rest"
                  >
                    <CardImageContainer imageSrc={card.imageSrc}>
                      {showRating && (
                        <CardRatingContainer>
                          <CardRating>
                            <StarIcon />
                            {card.rating}
                          </CardRating>
                          <CardReview>({card.reviews})</CardReview>
                        </CardRatingContainer>
                      )}
                      {showOverlay && (
                        <CardHoverOverlay
                          variants={{
                            hover: {
                              opacity: 1,
                              height: "auto",
                            },
                            rest: {
                              opacity: 0,
                              height: 0,
                            },
                          }}
                          transition={{ duration: 0.3 }}
                        >
                          <CardContent>{card.content}</CardContent>
                        </CardHoverOverlay>
                      )}
                    </CardImageContainer>
                    <CardText>
                      <CardTitle>{card.title}</CardTitle>
                      {showPrice && <CardPrice>{card.price}</CardPrice>}
                    </CardText>
                  </Card>
                </CardContainer>
              ))}
            {((infinite) => {
              if (infinite) {
                const data = tabs.find(({ name }) => name === tabKey).infinite;
                return (
                  <>
                    {data.loading && (
                      <SpinnerContainer>
                        <Spinner />
                      </SpinnerContainer>
                    )}
                    {data.errorFetching && (
                      <div>Error la obtener productos</div>
                    )}
                    {!data.loading && !data.errorFetching ? (
                      <InfiniteScroll loadMore={data.fetchMoreData} />
                    ) : null}
                    {!data.loading && !data.errorFetching && !data.length ? (
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          textAlign: "center",
                          paddingTop: "20px",
                          fontSize: "20px",
                        }}
                      >
                        No hay resultados
                      </div>
                    ) : null}
                  </>
                );
              }
            })(tabs.find(({ name }) => name === tabKey)?.infinite)}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards = () => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
      title: "Chicken Chilled",
      content: "Chicken Main Course",
      price: "$5.99",
      rating: "5.0",
      reviews: "87",
      url: "#",
    },
  ];
  return [...cards, ...cards, ...cards, ...cards, ...cards];
};

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyles from "styles/GlobalStyles";
import Home from "pages/Home";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import Advise from "pages/Advise";
import Stationery from "pages/Stationery";
import Services from "pages/Services";
import Calculator from "pages/Calculator";

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quienes-somos" element={<AboutUs />} />
          <Route path="/papeleria" element={<Stationery />} />
          <Route path="/servicios" element={<Services />} />
          <Route path="/tramites-digitales" element={<Advise />} />
          <Route path="/contacto" element={<ContactUs />} />
          <Route path="/calculadora" element={<Calculator />} />
        </Routes>
      </Router>
    </>
  );
}

import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsSocial from "components/features/TwoColContactUsWithIllustrationSocial.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import NavLinks from "components/misc/PapeNavLinks";
import { ReactComponent as MailIcon } from "feather-icons/dist/icons/mail.svg";
import { ReactComponent as WhatsappIcon } from "images/whatsapp-logo.svg";
import { ReactComponent as InstagramIcon } from "feather-icons/dist/icons/instagram.svg";
import { ReactComponent as FacebookIcon } from "feather-icons/dist/icons/facebook.svg";

const SocialIcon = tw.div`mt-2`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header links={[<NavLinks key={1} />]} />
      <ContactUsSocial
        subheading="¿Tienes dudas o comentarios?"
        heading={
          <>
            Puedes ponerte en <span tw="text-primary-500">contacto</span>
            <wbr /> con nosotros.
          </>
        }
        description={
          <>
            <div>
              Te responderemos lo más pronto posible. Encuéntranos en nuestros
              medios de contacto y nuestras redes sociales.
            </div>
          </>
        }
        socials={
          <>
            <SocialIcon>
              <MailIcon tw="text-pink-500" style={{ display: "inline" }} />{" "}
              <span>
                <a href="mailto:contacto@papelerialeo.com">
                  contacto@papelerialeo.com
                </a>
              </span>
            </SocialIcon>
            <SocialIcon>
              <WhatsappIcon
                style={{
                  width: "24px",
                  height: "24px",
                  display: "inline",
                  fill: "#38a169",
                }}
              />{" "}
              <span>
                <a
                  href="https://wa.me/4443057392"
                  target="_blank"
                  rel="noreferrer"
                >
                  4443057392
                </a>
              </span>
            </SocialIcon>
            <SocialIcon>
              <InstagramIcon tw="text-red-600" style={{ display: "inline" }} />{" "}
              <span>
                <a
                  href="https://www.instagram.com/leo.papeleria"
                  target="_blank"
                  rel="noreferrer"
                >
                  leo.papeleria
                </a>
              </span>
            </SocialIcon>
            <SocialIcon>
              <FacebookIcon tw="text-blue-600" style={{ display: "inline" }} />{" "}
              <span>
                <a
                  href="https://www.facebook.com/papelerialeoslp"
                  target="_blank"
                  rel="noreferrer"
                >
                  papelerialeoslp
                </a>
              </span>
            </SocialIcon>
            <br />
            <div style={{ fontWeight: "bold" }}>Horarios de atención:</div>
            <div style={{ marginRight: 16 }}>
              <div>
                <span style={{ width: "130px", display: "inline-block" }}>
                  Lunes a viernes:
                </span>{" "}
                8:00am- 7:00pm
              </div>
              <div>
                <span style={{ width: "130px", display: "inline-block" }}>
                  Sábados:
                </span>{" "}
                10:00am - 3:00pm
              </div>
              <div>
                <span style={{ width: "130px", display: "inline-block" }}>
                  Domingos:
                </span>
                11:00am - 5:00pm
              </div>
            </div>
          </>
        }
      />
      <ContactDetails
        heading="Ubicación"
        subheading="Visítanos"
        description={
          <>
            <div>Privada Avanzada 120 - 80</div>
            <div>Ciudad Satélite, 78423, San Luis Potosí, SLP.</div>
            <div style={{ fontSize: 16, marginTop: 12 }}>
              Entrada por Avenida del Parque, en la parada del camión, frente a
              Bodega Aurrera, casi esquina con la glorieta de entrada al parque
              industrial Colinas de San Luis.
            </div>
          </>
        }
        cards={[]}
      >
        <iframe
          title="Papelería e Internet Leo ubicación"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2152.616721109759!2d-100.79819310102192!3d22.113574612548252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842aaf80fc706fe3%3A0xf75b91d43c4c3b3!2sPapeler%C3%ADa%20e%20internet%20Leo!5e0!3m2!1ses!2smx!4v1697170037850!5m2!1ses!2smx"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </ContactDetails>
      <Footer />
    </AnimationRevealPage>
  );
};

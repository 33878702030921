import React, { useRef, useCallback } from "react";

const InfiniteScroll = ({ loadMore }) => {
  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loadMore]
  );

  return (
    <div>
      {/* Tu contenido va aquí */}
      <div ref={lastElementRef}></div>
    </div>
  );
};

export default InfiniteScroll;

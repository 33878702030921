import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover min-h-128`}
`;
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 py-16 flex flex-1 flex-col justify-center items-center`;
const Description = tw.div`py-10 flex-col justify-center items-center font-medium text-secondary-500 text-center text-2xl max-w-5xl`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-secondary-500 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default ({
  heading = (
    <>
      Book Music & Comedy Events
      <br />
      anywhere in New York
    </>
  ),
  description = "some description",
  primaryActionText,
}) => {
  return (
    <Container>
      <br />
      <HeroContainer>
        <Content>
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
          {primaryActionText && (
            <PrimaryAction>Search Events Near Me</PrimaryAction>
          )}
        </Content>
      </HeroContainer>
    </Container>
  );
};

import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating";
import Footer from "components/footers/MiniCenteredFooter.js";
import NavLinks from "components/misc/PapeNavLinks";
import Header from "components/headers/light.js";
import stationeryIcon from "images/stationery-icon.jpg";
import adviseIcon from "images/advise-icon.jpg";
import servicesIcon from "images/services-icon.jpg";
import papelerialeo1 from "images/papeleria-leo-1.jpg";
import papelerialeo2 from "images/papeleria-leo-2.jpg";
import papelerialeo3 from "images/papeleria-leo-3.jpg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  const year = new Date().getFullYear() - 2009;

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          alignItems: "center",
          textAlign: "center",
          minHeight: "80px",
          background:
            "linear-gradient(180deg, rgba(89,147,255,1) 27%, rgba(0,58,166,1) 97%)",
          color: "white",
          fontSize: 24,
          fontStyle: "italic",
          borderRadius: 10,
          margin: "10px 10px 0 10px ",
        }}
      >
        Aparta tu lista de útiles escolares y recibe un 10% de descuento. Cotiza
        sin compromiso. Evita filas!
      </div>
      <AnimationRevealPage>
        <Header links={[<NavLinks key={1} />]} />
        <Hero
          heading={
            <>
              La mejor <HighlightedText>Papelería.</HighlightedText>
            </>
          }
          description={
            <div style={{ fontSize: 20 }}>
              <div>Tu aliada en productos de papelería y asesoría digital.</div>
              <div>
                Descubre nuestra amplia gama de productos de papelería y
                servicios especializados. También ofrecemos asesoría en trámites
                digitales para facilitar tu vida diaria. Y todo al mejor precio.
              </div>
              <br />
              <div>
                ¡Inspírate y encuentra todo lo que necesitas con nosotros!
              </div>
            </div>
          }
          imageSrc={papelerialeo1}
          imageCss={imageCss}
          imageDecoratorBlob={true}
        />
        <MainFeature
          subheading={<Subheading>Establecidos desde 2009</Subheading>}
          heading={
            <>
              Brindando servicio por
              <wbr /> <HighlightedText>{year} años.</HighlightedText>
            </>
          }
          description={
            <Description>
              Nuestra experiencia habla por sí misma. Durante más de {year}{" "}
              años, hemos estado comprometidos en ofrecer soluciones confiables
              y servicios excepcionales. La dedicación a la excelencia y nuestra
              pasión por lo que hacemos nos han convertido en el recurso de
              confianza para suministros de oficina, servicios de impresión,
              copiado y asesoría en trámites digitales.
            </Description>
          }
          buttonRounded={false}
          textOnLeft={false}
          buttons={[]}
          imageSrc={papelerialeo2}
          imageCss={imageCss}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
        />
        <Features
          heading={
            <>
              Nuestras <HighlightedText>soluciones.</HighlightedText>
            </>
          }
          cards={[
            {
              imageSrc: stationeryIcon,
              title: "Papelería",
              description:
                "Encuentra una amplia gama de productos de alta calidad para tus necesidades escolares, de oficina y creativas.",
              url: "/papeleria",
            },
            {
              imageSrc: adviseIcon,
              title: "Trámites Digitales",
              description:
                "Simplifica tus gestiones con nuestra asesoría especializada en trámites digitales. Estamos aquí para ayudarte a navegar el mundo digital.",
              url: "/tramites-digitales",
            },
            {
              imageSrc: servicesIcon,
              title: "Servicios",
              description:
                "Ofrecemos una variedad de servicios adicionales para apoyar tus proyectos y necesidades diarias.",
              url: "/servicios",
            },
          ]}
          linkText="Ver más"
          imageContainerCss={tw`p-2!`}
          imageCss={tw`w-16! h-16! rounded-xl`}
        />
        <MainFeature2
          subheading={<Subheading>Una papelería de confianza</Subheading>}
          heading={
            <>
              ¿Porqué <HighlightedText>nosotros</HighlightedText> ?
            </>
          }
          description={
            <div>
              <div style={{ marginBottom: 8 }}>
                - Ofrecemos una amplia selección de productos de papelería de la
                más alta calidad para satisfacer todas tus necesidades.
              </div>
              <div style={{ marginBottom: 8 }}>
                - Nuestro equipo está siempre disponible para ofrecerte asesoría
                personalizada en trámites digitales, ayudándote a simplificar y
                agilizar tus gestiones.
              </div>
              <div style={{ marginBottom: 8 }}>
                - Tu satisfacción es nuestra prioridad. Trabajamos continuamente
                para mejorar nuestros productos y servicios, asegurándonos de
                superar tus expectativas.
              </div>
            </div>
          }
          statistics={[]}
          primaryButtonText="Conocenos"
          primaryButtonUrl="/quienes-somos"
          imageInsideDiv={false}
          imageSrc={papelerialeo3}
          imageCss={Object.assign(tw`bg-cover`, imageCss)}
          imageContainerCss={tw`md:w-1/2 h-auto`}
          imageDecoratorBlob={true}
          imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
          textOnLeft={true}
        />
        {/* <Testimonial
        subheading=""
        heading={
          <>
            Lo que opinan <HighlightedText>Nuestros Clientes.</HighlightedText>
          </>
        }
        description="Algunas opiniones de nuestros clientes más leales."
        testimonials={[
          {
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing Services",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
            customerName: "Charlotte Hale",
          },
          {
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Friendly Staff",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
            customerName: "Adam Cuppy",
          },
          {
            profileImageSrc:
              "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=512&h=512&q=80",
            heading: "Always Helpful",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
            customerName: "Steven Marcetti",
          },
        ]}
      /> */}
        <Footer />
      </AnimationRevealPage>
    </>
  );
};

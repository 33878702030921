import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground";
import SliderCard from "components/cards/ThreeColSlider.js";
import FAQ from "components/faqs/SingleCol";
import Footer from "components/footers/MiniCenteredFooter.js";
import DashedCards from "components/features/DashedBorderFeatureCards";
import NavLinks from "components/misc/PapeNavLinks";
import acta from "images/acta-de-nacimiento.jpg";
import curp from "images/curp.jpg";
import cartaNoAntecedentes from "images/carta-no-antecedentes-penales.jpg";

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
const ItalicText = tw.span`italic text-xs`;
const Link = tw.a`inline-block text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;
const Notes = tw.span`font-medium text-sm text-gray-600`;
const ReqContainer = tw.div`flex flex-col items-start justify-start`;
const ReqTitle = tw.h6`font-bold text-sm text-primary-500`;
const ReqList = tw.ul`mb-2 ml-4 list-disc`;
const ReqItem = tw.li`mt-1`;

export default () => (
  <AnimationRevealPage>
    <Hero
      heading={
        <>
          Asesoría en{" "}
          <HighlightedTextInverse>Trámites Digitales</HighlightedTextInverse>
        </>
      }
      description={
        <>
          Comprendemos que los trámites digitales pueden ser un desafío, por eso
          te ofrecemos servicios de asesoría para simplificarlos.
          <br />
          <br />
          Nuestro equipo está listo para proporcionarte asesoría personalizada.
          Deja atrás las preocupaciones y el estrés de no saber como realizar
          tus trámites y descubre cómo Papelería e Internet Leo puede
          facilitarte el proceso.
          <br />
          <br />
          <br />
          <br />
          <br />
          <Notes>
            <ItalicText>
              * El servicio que proporcionamos y por el que cobramos nosotros es
              sólo por el de la asesoría, ayuda, y material usado (de ser
              necesario). Los trámites son realizados en los sitios oficiales de
              cada trámite, cada instancia define el costo de su trámite el cuál
              deberá cubrir el cliente. El éxito del trámite depende de la
              información proporcionada por el cliente, y de la disponibilidad
              de los sitios oficiales. No modificamos, creamos, ni alteramos
              ningún documento, sólo brindamos asesoría y ayuda con el trámite.
            </ItalicText>
          </Notes>
        </>
      }
      links={[<NavLinks key={1} />]}
    />
    {/* <Features /> */}
    <SliderCard
      headingText={
        <>
          <HighlightedText>Trámites</HighlightedText> Populares
        </>
      }
      cards={[
        {
          imageSrc: curp,
          title: "CURP",
          description:
            "Clave Única de Registro de Población. Identificación de cada ciudadano mexicano y residente temporal o permanente en México.",
        },
        {
          imageSrc: acta,
          title: "Acta de Nacimiento",
          description:
            "Acredita tu nombre, tu sexo, tu nacionalidad mexicana, indica la fecha y lugar de tu nacimiento.",
        },
        {
          imageSrc: cartaNoAntecedentes,
          title: "Carta de No Antecedentes Penales",
          description:
            "Documento/constancia de la existencia o no de antecedentes penales.",
        },
      ]}
    />
    <DashedCards
      headingText={
        <>
          Todos los Trámites y <HighlightedText>Requisitos</HighlightedText>
        </>
      }
      cards={[
        {
          title: "CURP",
          description: (
            <ReqContainer>
              <ReqTitle>Opción 1:</ReqTitle>
              <ReqList>
                <ReqItem>Nombre completo</ReqItem>
                <ReqItem>Fecha de nacimiento</ReqItem>
                <ReqItem>Sexo</ReqItem>
                <ReqItem>Entidad de nacimiento (Estado)</ReqItem>
              </ReqList>
              <ReqTitle>Opción 2:</ReqTitle>
              <ReqList>
                <ReqItem>INE (ya que contiene la clave)</ReqItem>
              </ReqList>
            </ReqContainer>
          ),
        },
        {
          title: "Acta de Nacimiento",
          description: (
            <ReqContainer>
              <ReqList>
                <ReqItem>Nombre completo</ReqItem>
                <ReqItem>Fecha de nacimiento</ReqItem>
                <ReqItem>Entidad de nacimiento (Estado)</ReqItem>
                <ReqItem>Nombre completo de alguno de los padres</ReqItem>
              </ReqList>
            </ReqContainer>
          ),
        },
      ]}
    />
    <FAQ
      heading={
        <>
          ¿Tienes <HighlightedText>preguntas?</HighlightedText>
        </>
      }
      subheading=""
      description={
        <>
          No dudes en <Link href="/contacto">contactarnos</Link>. Estamos para
          servirte.
        </>
      }
      faqs={[]}
    />
    <Footer />
  </AnimationRevealPage>
);
